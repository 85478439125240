import React, {Component, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import FileSaver from "file-saver";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Shop from "../services/shopService";
import {isAdmin} from "../services/authService";


import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Pagination from "./common/pagination";
import {searchInShopTable} from "../utils/search";
import {paginate} from "../utils/paginate";

import _ from "lodash";
import SearchBox from "./common/searchBox";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import SimpleSelect from "./common/simpleSelect";
import MusicInShopsTable from "./musicInShopsTable";
import PlaylistsService from "../services/playlistsService";
import ConfirmationButton from "./common/confirmationButton";
import dictionary from "../PL.json";
import ConfirmationModalContextProvider from "./common/modalConfirmationContext";
import {findShopsInUseDuringLastHour, findShopsWithDevices} from "../utils/select";
import * as AuthDevices from "../services/authDeviceService";
import ProgressModal from "./common/progressModal";

const containerStyle = {
    width: "100%", height: "400px", margin: "auto",
};

export function MusicInShops(props) {

    const [playlists, setPlaylists] = useState([]);
    const [shops, setShops] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortColumn, setSortColumn] = useState({path: "name", order: "asc"});
    const [searchQuery, setSearchQuery] = useState("");
    const [inUse, setInUse] = useState(true);
    const [inUseLastHour, setInUseLastHour] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedPlaylistForChecking, setSelectedPlaylistForChecking] = useState({
        _id: "1", name: "Wybierz playlistę"
    });

    const [selectedPlaylistToGenerateReport, setSelectedPlaylistToGenerateReport] = useState({
        _id: "1", name: "Wybierz playlistę"
    });

    const [showConfirmationModal,setShowConfirmationModal] = useState(false);

    const history = useHistory();

    const uploadPlaylists = async () => {
        try {
            let result = await PlaylistsService.getAll();
            setPlaylists([{_id: "1", name: "Wybierz playlistę"}, ...result.data]);
        } catch (e) {

        }
    }

    const uploadAuthMADevices = async () => {
        try {
            let {data: result} = await AuthDevices.getAll();
            result = result.filter((device) => device.dev_type === "MA");
            return result;
        } catch (e) {
            return null;
        }
    }

    const updateShops = async (_inUse, _inUseLastHour) => {
        let oldShops = [...shops];

        try {
            let newShops = [];
            if (_inUse) {
                // const {data: shops} = await Shop.getAllWithDevicesAssigned();
                const {data} = await Shop.getAllShopsAndDevices();

                newShops = findShopsWithDevices(data.allShops, data.devices);

            } else if (_inUseLastHour) {
                // const {data: shops} = await Shop.getAllWithDevicesActiveLastHour();
                const {data} = await Shop.getAllShopsAndDevices();

                newShops = findShopsInUseDuringLastHour(data.allShops, data.devices);
            } else {
                const {data} = await Shop.getAll();
                newShops = data;
            }

            if (oldShops.length > 0) {
                newShops.forEach((newShop) => {
                    let oldShop = oldShops.find((shop) => shop._id === newShop._id);
                    if (oldShop) {
                        newShop.checked = oldShop?.checked ? oldShop.checked : false;
                    }
                });
            }

            setShops(newShops);
        } catch (err) {
            // toast.error("Błąd serwera!!!");
            history.push("/not-found");
        }

    }

    useEffect(() => {
        uploadPlaylists();
        updateShops(inUse, inUseLastHour);

    }, []);

    useEffect(() => {
    }, [sortColumn]);

    const handleEdit = (event, shop) => {
        event.stopPropagation();
        history.push("/shops/" + shop._id);
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSort = (_sortColumn) => {
        if (_sortColumn.path === "") return;
        setSortColumn({..._sortColumn});

    };

    const getPagedData = (_shops, _sortColumn) => {
        const allShops = [..._shops];

        let filtered = allShops;

        if (searchQuery) {
            filtered = searchInShopTable(allShops, ["name", "city", "adress"], searchQuery);
        }

        const sorted = _.orderBy(filtered, [_sortColumn.path], [_sortColumn.order]);

        const newShops = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: newShops};
    };

    const handleSearch = (query) => {
        setCurrentPage(1);
        setSearchQuery(query);
    };

    const handleInUseChange = async ({target}) => {
        setInUseLastHour(false);
        setInUse(target.checked);
        setCurrentPage(1);
        await updateShops(target.checked, false);
    };

    const handleInUseLastHourChange = async ({target}) => {
        setInUse(false);
        setInUseLastHour(target.checked);

        setCurrentPage(1);
        await updateShops(false, target.checked);
    };

    const countSelected = (shops) => {
        let newSelectedCount = 0;

        shops.forEach((shop) => {
            if (shop.checked !== undefined && shop.checked) newSelectedCount++;
        });

        setSelectedCount(newSelectedCount)
    }

    const handleCheckClick = (event, shop) => {
        event.stopPropagation();
        let newShops = [...shops];
        let index = newShops.findIndex((s) => s._id === shop._id);
        if (index !== -1) {
            newShops[index].checked = event.target?.checked;
            setShops(newShops);

            countSelected(newShops);

        }
    }

    const handleRowClick = (shop) => {
        let newShops = [...shops];
        let index = newShops.findIndex((s) => s._id === shop._id);
        if (index !== -1) {
            newShops[index].checked = newShops[index]?.checked !== undefined ? !newShops[index].checked : true;
            setShops(newShops);

            let newSelectedCount = 0;

            newShops.forEach((shop) => {
                if (shop.checked !== undefined && shop.checked) newSelectedCount++;
            });

            setSelectedCount(newSelectedCount)

        }
    }

    const {totalCount, data: shopsPagineted} = getPagedData(shops, sortColumn);

    function setPlaylistToCheck(value) {
        const index = playlists.findIndex((p) => p._id === value);
        if (index !== -1) {
            setSelectedPlaylistForChecking(playlists[index]);
        }
    }

    function setPlaylistToGenerateReport(value) {
        const index = playlists.findIndex((p) => p._id === value);
        if (index !== -1) {
            setSelectedPlaylistToGenerateReport(playlists[index]);
        }
    }

    function handleCheckAll() {
        let allShops = [...shops];
        allShops.forEach((shop) => {
            shop.checked = true;
        });
        setShops(allShops);
        setSelectedCount(allShops.length);
    }

    function handleUncheckAll() {
        let allShops = [...shops];
        allShops.forEach((shop) => {
            shop.checked = false;
        });
        setShops(allShops);
        setSelectedCount(0);
    }

    async function handleAddPlaylist() {
        try {
            let shopsIds = shops.map((shop) => {
                if (shop?.checked !== null && shop?.checked !== undefined && shop?.checked) {
                    return shop._id;
                }
            });
            const {data: modifiedShops} = await Shop.putPlaylistAdd(shopsIds, selectedPlaylistForChecking._id);

            await updateShops(inUse, inUseLastHour);
            toast.success("Pomyślnie dodano playlistę do wybranych sklepów!!!");
        } catch (e) {
            toast.error("Wystąpił błąd podczas dodawania playlisty do wybranych sklepów!!!")
        }

    }

    function handleSelectAllWithSelectedPlaylist() {
        let allShops = [...shops];
        allShops.forEach((shop) => {
            if (shop.playlistsIds.length > 0 && shop.playlistsIds.includes(selectedPlaylistForChecking._id)) {
                shop.checked = true;
                return;
            }
        });
        countSelected(allShops);
        setShops(allShops);

    }

    function handleUnSelectAllWithSelectedPlaylist() {
        let allShops = [...shops];
        allShops.forEach((shop) => {
            if (shop.playlistsIds.length > 0 && shop.playlistsIds.includes(selectedPlaylistForChecking._id)) {
                shop.checked = false;
                return;
            }
        });
        countSelected(allShops);
        setShops(allShops);

    }


    async function handleGeneratePlaylistReport() {
        let allShops = [...shops];
        setShowConfirmationModal(true);
        let result = allShops.filter((shop) => {
            if (shop.playlistsIds !== null || shop.playlistsIds !== undefined) {
                if (shop.playlistsIds.length > 0 && shop.playlistsIds[0] === selectedPlaylistToGenerateReport._id) {
                        return true;
                }
            }
            return false;
        });

        if (result !== undefined) {
            let maDevices = await uploadAuthMADevices();

            result = result.filter((shop) => {
                const index = maDevices.findIndex((device) => device.shop_id === shop._id);
                if(index !== -1)
                {
                   if(maDevices[index]?.conf?.playlistEnable === true)
                   {
                       return true;
                   }
                }
            });

            if (result?.length > 0) {
                let csv = "Numer Drogerii; Adres; Miasto; Kod Pocztowy;\n";

                result.forEach((shop) => {
                    csv += shop.name + ";" + shop.adress + ";" + shop.city + ";" + shop.postCode + ";\n";
                });

                var blob = new Blob([csv], {
                    type: "text/plain;charset=utf-8",
                });

                FileSaver.saveAs(blob, "Playlista_" + selectedPlaylistToGenerateReport.name + "_" + (new Date(Date.now())).toLocaleString() + ".csv");
            }
            else {
                toast.error("Brak sklepów odgrywających wybraną playlistę!!!");
            }
            setShowConfirmationModal(false);
        }
    }

    async function handleGenerateIMSPlaylistReport() {
        let allShops = [...shops];

        if (allShops !== undefined) {
            let maDevices = await uploadAuthMADevices();

            let result = allShops.filter((shop) => {
                const index = maDevices.findIndex((device) => device.shop_id === shop._id);
                if(index !== -1)
                {
                    if(maDevices[index]?.conf?.playlistIMSEnable === true)
                    {
                        return true;
                    }
                }
            });

            if (result?.length > 0) {
                let csv = "Numer Drogerii; Adres; Miasto; Kod Pocztowy;\n";

                result.forEach((shop) => {
                    csv += shop.name + ";" + shop.adress + ";" + shop.city + ";" + shop.postCode + ";\n";
                });

                var blob = new Blob([csv], {
                    type: "text/plain;charset=utf-8",
                });

                FileSaver.saveAs(blob, "Raport_IMS_" + (new Date(Date.now())).toLocaleString() + ".csv");
            }
            else {
                toast.error("Brak sklepów odgrywających playlistę IMS!!!");
            }
            setShowConfirmationModal(false);
        }
    }

    async function handleRemovePlaylist() {
        try {
            let shopsIds = shops.map((shop) => {
                if (shop?.checked !== null && shop?.checked !== undefined && shop?.checked) {
                    return shop._id;
                }
            });
            const {data: modifiedShops} = await Shop.putPlaylistRemove(shopsIds, selectedPlaylistForChecking._id);

            await updateShops(inUse, inUseLastHour);
            toast.success("Pomyślnie usunięto playlistę z wybranych sklepów!!!");
        } catch (e) {
            toast.error("Wystąpił błąd podczas usuwania playlisty z wybranych sklepów!!!")
        }

    }

    async function handleRemoveAllPlaylist() {
        try {
            let shopsIds = shops.map((shop) => {
                if (shop?.checked !== null && shop?.checked !== undefined && shop?.checked) {
                    return shop._id;
                }
            });
            const {data: modifiedShops} = await Shop.putPlaylistRemoveAll(shopsIds);

            await updateShops(inUse, inUseLastHour);
            toast.success("Pomyślnie usunięto playlisty z wybranych sklepów!!!");
        } catch (e) {
            toast.error("Wystąpił błąd podczas usuwania playlist z wybranych sklepów!!!")
        }

    }

    return (


        // if (count === 0) {
        //   return (
        //     <React.Fragment>
        //       <p>Nie ma urządzeń oczekujących na akceptację!!!</p>
        //     </React.Fragment>
        //   );
        // }


        <React.Fragment>
            <ConfirmationModalContextProvider>
                <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                    <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}} active>
                        Sklepy
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Container fluid>
                    <Row sm="12" md="10" className="pt-2 flex-container__right">

                        <Col lg="3" md="4" style={{textAlign: "center"}}>
                            <label>
                                Sklepy tylko z urządzeniami:
                                <input
                                    className="m-2"
                                    name="inUse"
                                    type="checkbox"
                                    checked={inUse}
                                    onChange={handleInUseChange}
                                />
                            </label>
                        </Col>
                        <Col  lg="4" md="4" style={{textAlign: "center"}}>
                            <label>
                                tylko z aktywnymi urządzeniami
                                <input
                                    className="m-2"
                                    name="inUseLastHour"
                                    type="checkbox"
                                    checked={inUseLastHour}
                                    onChange={handleInUseLastHourChange}
                                />
                            </label>
                        </Col>
                        <Col  lg="3" md="4" style={{textAlign: "center"}}>
                            <label>Liczba sklepów: {shops.length}</label>
                        </Col>

                    </Row>

                    <Row className={" flex-container__right"}>
                        <Col lg="10" style={{textAlign: "center"}}>
                            <SearchBox value={searchQuery} onChange={handleSearch}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <React.Fragment>
                                <Accordion
                                    defaultActiveKey=""
                                    // activeKey={settingsMode ? "0" : "1"}
                                    className=" mt-2"
                                >
                                    <Accordion.Item eventKey="0" className="border-primary border-">
                                        <Accordion.Header
                                            onClick={(event) => {
                                                // this.setState({settingsMode: !settingsMode});
                                            }}
                                        >
                                            <div className="w-100 text-center">
                                                <span className="fw-bold">Ustawienia</span>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div
                                                className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                                Zaznaczonych: {selectedCount}
                                            </div>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                className="fw-bold w-100"
                                                onClick={() => handleCheckAll()}
                                            >
                                                Zaznacz wszystkie
                                            </Button>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                className="fw-bold w-100 mt-1"
                                                onClick={() => handleUncheckAll()}
                                            >
                                                Odznacz wszystkie
                                            </Button>
                                            {selectedCount > 0 && (<ConfirmationButton
                                                label={"Usunąć playlisty z zaznaczonych urządzeń?"}
                                                yesLabel={"TAK"}
                                                noLabel={"NIE"}
                                                variant="danger"
                                                className="fw-bold w-100 mt-1"
                                                size="sm"
                                                onClick={handleRemoveAllPlaylist}
                                            >
                                                Usuń wszystkie playlisty z zaznaczonych
                                            </ConfirmationButton>)}
                                            <hr></hr>
                                            <div
                                                className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                                {/*<label className="align-self-center fw-bold">FW:</label>*/}
                                                <SimpleSelect
                                                    value={selectedPlaylistForChecking._id}
                                                    className="m-2 w-100"
                                                    optionName="name"
                                                    optionKey="_id"
                                                    options={playlists}
                                                    onChange={(e) => setPlaylistToCheck(e.target.value)}
                                                />
                                            </div>
                                            {selectedPlaylistForChecking._id !== "1" && (<React.Fragment>
                                                {selectedCount > 0 && (<ConfirmationButton
                                                    label={"Zmienić przypisane playlisty?"}
                                                    yesLabel={"TAK"}
                                                    noLabel={"NIE"}
                                                    variant="success"
                                                    className="fw-bold w-100 mt-1"
                                                    size="sm"
                                                    onClick={handleAddPlaylist}
                                                >
                                                    Dodaj playlistę do zaznaczonych
                                                </ConfirmationButton>)}
                                                {selectedCount > 0 && (<ConfirmationButton
                                                    label={"Usunąć playliste \"" + selectedPlaylistForChecking.name + "\" z zaznaczonych urządzeń?"}
                                                    yesLabel={"TAK"}
                                                    noLabel={"NIE"}
                                                    variant="danger"
                                                    className="fw-bold w-100 mt-1"
                                                    size="sm"
                                                    onClick={handleRemovePlaylist}
                                                >
                                                    Usuń playlistę z zaznaczonych
                                                </ConfirmationButton>)}

                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="fw-bold w-100 mt-1"
                                                    onClick={handleSelectAllWithSelectedPlaylist}
                                                >
                                                    Zaznacz po playliście
                                                </Button>
                                                <Button
                                                    variant="warning"
                                                    size="sm"
                                                    className="fw-bold w-100 mt-1"
                                                    onClick={handleUnSelectAllWithSelectedPlaylist}
                                                >
                                                    Odznacz po playliście
                                                </Button>
                                            </React.Fragment>)}

                                            {/* <p className="text-center mb-1">
                          Zaznaczonych: <span className="fw-bold">{selectedCount}</span>
                        </p> */}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion
                                    defaultActiveKey=""
                                    // activeKey={settingsMode ? "0" : "1"}
                                    className=" mt-2"
                                >
                                    <Accordion.Item eventKey="0" className="border-primary border-">
                                        <Accordion.Header
                                            onClick={(event) => {
                                                // this.setState({settingsMode: !settingsMode});
                                            }}
                                        >
                                            <div className="w-100 text-center">
                                                <span className="fw-bold">Raporty</span>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div
                                                className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                                {/*<label className="align-self-center fw-bold">FW:</label>*/}
                                                <SimpleSelect
                                                    value={selectedPlaylistToGenerateReport._id}
                                                    className="m-2 w-100"
                                                    optionName="name"
                                                    optionKey="_id"
                                                    options={playlists}
                                                    onChange={(e) => setPlaylistToGenerateReport(e.target.value)}
                                                />
                                            </div>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                className="fw-bold w-100 mt-1"
                                                onClick={handleGeneratePlaylistReport}
                                            >
                                                Sklepy odgrywające playlistę
                                            </Button>
                                            <hr></hr>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                className="fw-bold w-100 mt-1"
                                                onClick={handleGenerateIMSPlaylistReport}
                                            >
                                                Sklepy odgrywające playlistę IMS
                                            </Button>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </React.Fragment>

                        </Col>
                        <Col ms="10" xs="10">
                            <div className={"mt-2"}>

                            <Pagination
                                className="align-content-end"
                                style={{width: "auto"}}
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                            </div>
                            <MusicInShopsTable
                                shops={shopsPagineted}
                                playlists={playlists}
                                sortColumn={sortColumn}
                                onEdit={handleEdit}
                                // onAdd={handleAdd}
                                onSort={handleSort}
                                onRowClick={handleRowClick}
                                checkedVisible={true}
                                onCheckClick={handleCheckClick}
                            />
                            <Pagination
                                className="align-content-end"
                                style={{width: "auto", paddingBottom: "20px"}}
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </Col>

                    </Row>

                    <ProgressModal
                        show={showConfirmationModal}
                        actual={0}
                        max={0}
                    />
                </Container>
            </ConfirmationModalContextProvider>

        </React.Fragment>);

}

export default MusicInShops;
