import http from "./httpService";

const apiEndpoint = "/api/authDevices";

export function add(data) {
  return http.post(apiEndpoint, data);
}

// export function update(_id,data){
//     return http.put(apiEndpoint+"/"+_id,data);
// }


export function get(_id) {
  return http.get(apiEndpoint + "/" + _id);
}

export function getAll() {
  return http.get(apiEndpoint);
}

export function getWifi(_id) {
  return http.get(apiEndpoint + "/wifi/" + _id);
}

export function getByShopId(_id) {
  return http.get(apiEndpoint + "/shop/" + _id);
}

export function remove(_id) {
  return http.delete(apiEndpoint + "/" + _id);
}

export function postConf(data) {
  return http.post(apiEndpoint + "/conf", data);
}

export function updateInfo(data) {
  return http.put(apiEndpoint + "/info", data);
}

export function updateAlwaysActive(data) {
  return http.put(apiEndpoint + "/alwaysActive", data);
}

export function updateTags(data) {
  return http.put(apiEndpoint + "/tags", data);
}

export function updateWifi(_id, _wifiId) {
  return http.put(apiEndpoint + "/wifi/" + _id + "/" + _wifiId);
}

export function retryWifi(_id) {
  return http.put(apiEndpoint + "/wifi.retry/" + _id );
}

export function newEndpoint(_id) {
  return http.put(apiEndpoint + "/newEndpoint/" + _id );
}

export function deleteNewEndpoint(_id) {
  return http.delete(apiEndpoint + "/newEndpoint/" + _id );
}

export function clearWifi(_id) {
  return http.delete(apiEndpoint + "/wifi/" + _id);
}

export function deleteAllMusicFiles(_id) {
  return http.put(apiEndpoint + "/deleteMusicFiles/" + _id);
}



