import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = "/api/auth";
const tokenKey = "token";

http.setJwt(getJwt());

const enable = process.env.REACT_APP_AUTH_ENABLE;

export async function login(username, password) {
  const { data: jwt } = await http.post(apiEndpoint, { username, password }, { headers: { "Access-Control-Allow-Origin": "*" } });

  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("token");

    if (enable === "true") {
      const user = jwtDecode(jwt);
      return user;
    }
    else
    {
      const user = {isAdmin: true, isService: true};
      return user;
    }

    
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem?.("token");
}

export function isAdmin() {
  try {
    const jwt = localStorage.getItem("token");

    let user = "ADMIN";
    if (enable === "true") {
      user = jwtDecode(jwt);
    } else {
      return true;
    }

    if (user && user.isAdmin) return true;
    return false;
  } catch (ex) {
    return null;
  }
}

export function isService() {
  try {
    const jwt = localStorage.getItem("token");
    let user = "ADMIN";
    if (enable === "true") {
      user = jwtDecode(jwt);
    } else {
      return true;
    }

    if (user && user.isService) return true;
    return false;
  } catch (ex) {
    return null;
  }
}

export function isAdminOrIsService() {
  try {
    const jwt = localStorage.getItem("token");
    let user = "ADMIN";
    if (enable === "true") {
      user = jwtDecode(jwt);
    } else {
      return true;
    }

    if (user && (user.isService || user.isAdmin)) return true;
    return false;
  } catch (ex) {
    return null;
  }
}
