import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


import {toast} from "react-toastify";

import FileSaver from "file-saver";

import SimpleSelectLabel from "./common/simpleSelectLabel";
import SimpleSelect from "./common/simpleSelect";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";
import ConfirmationButton from "./common/confirmationButton";

import dictionary from "../PL.json";
import config from "../config.json";

import * as AuthDeviceService from "../services/authDeviceService";
import ShopService from "../services/shopService";
import * as LogService from "../services/logService";

import {prepareDateWithSec, prepareDateWithoutHours} from "../utils/dateFormat";

import {isAdminOrIsService} from "../services/authService";
import {date} from "joi";
import {result} from "lodash";
import Shop from "../services/shopService";
import * as ImsService from "../services/imsService";
import * as AudioplayerLogService from "../services/audioplayerLogService";
import {findShopsWithDevices} from "../utils/select";

class AudioplayerLogView extends Component {
    state = {
        shops: [],
        shopsToShow: [],
        shopAuthDevices: [],
        imsFiles: [],
        searchShopQuery: "",
        selectedShop: null,
        selectedDevice: {},
        logsJSON: [],
        logs: "",
        searchQuery: "",
        selectedDate: prepareDateWithoutHours(new Date(Date.now())),
    };

    async componentDidMount() {
        let shopId = null;
        if (this.props.match.params.shopId) {
            shopId = this.props.match.params.shopId;
        }

        await this.getData();


        if (!shopId) return;

        await this.getShopDevicesFromServer(shopId);
    }

    getData = async () => {
        try {
            const {data} = await Shop.getAllShopsAndDevices();
            const shops = findShopsWithDevices(data.allShops, data.devices);

            // const imsEndpoint = await ImsService.getImsEndpoint();
            // if (imsEndpoint !== null) {
                const {data: imsFiles} = await ImsService.getAll();
                // console.log(imsFiles);
                this.setState({imsFiles});
            // }

            this.setState({shops, shopsToShow: shops});
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    getShopDevicesFromServer = async (shopId) => {
        try {
            let {data: shopAuthDevices} = await AuthDeviceService.getByShopId(shopId);
            shopAuthDevices = shopAuthDevices.map((device) => {
                return {...device, preattySN: device.sn + " " + device.dev_type};
            });
            shopAuthDevices = shopAuthDevices.sort((a, b) => Number(a.sn) - Number(b.sn));

            this.setState({shopAuthDevices});
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    handleShopChange = async ({currentTarget: input}) => {
        const selectedShop = input.value;
        // let selectedShop = this.state.shops.find((shop) => shop._id === input.value);
        this.setState({selectedShop});
        if (selectedShop) {
            await this.getShopDevicesFromServer(selectedShop);
        } else {
            this.setState({shopAuthDevices: [], selectedDevice: {}});
        }
    };


    handleDateChange = async ({currentTarget: input}) => {
        const value = input.value;
        const date = new Date(value);
        this.setState({selectedDate: date});
    };

    formatLogToTxt = (log) => {
        let result = "";

        result += prepareDateWithSec(log.timestamp * 1000) + ";";
        result += log.type + ";";
        result += log.sn + ";";
        result += "C:" + log.event + ";";
        result += log.msg + ";\n";
        return result;
    };

    handleEksport = async () => {
        const {logsJSON} = this.state;

        let result = "";
        logsJSON.forEach((log) => (result += this.formatLogToTxt(log)));
        var blob = new Blob([result], {
            type: "text/plain;charset=utf-8",
        });

        FileSaver.saveAs(blob, "log_" + (new Date(Date.now())).toLocaleString() + ".txt");
    };

    handleFilter = async () => {
        const {selectedShop, selectedDate} = this.state;

        if (selectedShop) {
            const data = {};

            // const device = shopAuthDevices.find((device) => device._id === selectedDevice);
            // if (device) data.sn = device.sn;
            if (selectedDate) data.day = selectedDate;


            const {data: logs} = await AudioplayerLogService.getByShopAndDay(this.state.selectedShop, data.day);
            if (logs && logs.length > 0) {
                const logsJSON = [];
                //! rozbijanie eventów z paczki na pojedyncze logi
                logs.forEach((log) => {
                    let {sn, timestamp, event, msg} = log;


                        let file = this.state.imsFiles.find((file) => file._id === log.msg);
                        if(file)
                        {
                            msg = file.name;
                        }


                        const logJSON = {timestamp, event, msg};

                        logsJSON.push(logJSON);

                });
                this.setState({logsJSON: logsJSON.reverse()});
            } else {
                this.setState({logsJSON: []});
            }
        }
    };

    handleSearchShop = async (query) => {
        const shops = this.filterShopsList(query);

        if (shops.length > 0) {
            this.setState({shopsToShow: shops, selectedShop: shops[0]._id, searchQuery: query});
            await this.getShopDevicesFromServer(shops[0]._id);
        } else {
            this.setState({
                shopsToShow: shops,
                selectedShop: null,
                shopAuthDevices: [],
                searchQuery: query,
            });
        }
    };

    filterShopsList = (searchQuery) => {
        const {shops: allShops} = this.state;

        if (searchQuery) {
            const shops = allShops.filter((shop) => {
                let result = false;
                let shopNo = Number(shop.name);
                let query = Number(searchQuery);
                if (query != null && shopNo != null) {
                    result = shopNo === query;
                }
                return result;
            });

            let selectedShop = this.state.data;
            if (shops.length > 0) {
                selectedShop = shops[0]._id;
            } else {
                selectedShop = null;
            }
            this.setState({selectedShop});
            return shops;
        }

        return allShops;
    };

    generateDate = () => {
        let selectedDate = this.state.selectedDate;
        const date = prepareDateWithoutHours(selectedDate);
        return date;
    };

    formatLogs = (log, index) => {
        let colorMark = log.service ? "#942911" : "#f5cb42";
        let colorService = log.service ? "#942911" : "#000000";
        let colorLine = index % 2 === 0 ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0.3)";
        return (
            <p
                key={"log_" + index}
                style={{color: "black", padding: "0", margin: "0", backgroundColor: colorLine}}
            >
                <b style={{color: "#121619"}}> {prepareDateWithSec(log.timestamp)} </b>
                <mark
                    style={{
                        backgroundColor: colorMark,
                        display: "inline-block",
                        padding: "7px",
                        borderRadius: "4px",
                        fontSize: "12px",
                    }}
                >
                    {/* { sn, fw, hw,type, timestamp, event, message }; */}
                </mark>

                <b style={{color: colorService}}>{" " + log.msg + " "}</b>
                {"    C:" + log.event + " "}

            </p>
        );
    };

    // log.message +
    // "; KOD: " +
    // log.meta.event +
    // "\n";

    render() {
        const {
            shops,
            shopsToShow,
            shopAuthDevices,
            selectedShop,
            selectedDevice,
            logsJSON,
            searchQuery,
            selectedDate,
        } = this.state;

        return (
            <React.Fragment>
                <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                    <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}} href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}>Zarządzanie muzyką</Breadcrumb.Item>
                    <Breadcrumb.Item style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}} active>Historia</Breadcrumb.Item>
                </Breadcrumb>
                <Container fluid>
                    <Row md="12" xs="12" className="mx-1">
                        <Col lg="3" md="4">
                            <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                <label className="align-self-center fw-bold">Sklep:</label>
                                <SimpleSelect
                                    value={selectedShop == null ? {} : selectedShop}
                                    className="m-2 w-100"
                                    style={{minWidth: "60px"}}
                                    optionName="name"
                                    optionKey="_id"
                                    options={shopsToShow}
                                    onChange={this.handleShopChange}
                                />
                                <input
                                    type="text"
                                    name="query"
                                    className="w-100 m-2"
                                    onChange={(e) => this.handleSearchShop(e.currentTarget.value)}
                                    value={searchQuery}
                                    placeholder={"Szukaj..."}
                                />
                            </div>

                            {selectedShop ? (
                                <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                    <label className="align-self-center fw-bold">Dzień:</label>
                                    <input
                                        className="m-2 w-100"
                                        value={this.generateDate()}
                                        name="data"
                                        type="date"
                                        onChange={this.handleDateChange}
                                    />
                                </div>
                            ) : null}
                            {selectedShop ? (
                                <React.Fragment>
                                    <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                        <Button onClick={this.handleFilter} size="sm" className="mb-2 me-2 w-100">
                                            Pokaż
                                        </Button>
                                    </div>
                                    {logsJSON.length > 0 ? (
                                        <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                            <p className="align-self-center fw-bold">
                                                Znalezionych wpisów: {logsJSON.length}
                                            </p>
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                        </Col>
                        <Col
                            lg="9"
                            md="8"
                            className="overflow-auto"
                            style={{
                                height: "500px",
                                padding: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.05)",
                                border: "1px solid rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            {logsJSON.length > 0
                                ? logsJSON.map((log, index) => this.formatLogs(log, index))
                                : null}
                        </Col>
                    </Row>
                    {logsJSON.length > 0 ? (
                        <Row>
                            <Col lg="3" md="4"></Col>
                            <Col lg="9" md="8">
                                <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                    <Button
                                        onClick={this.handleEksport}
                                        size="sm"
                                        variant="success"
                                        className="mb-2 mt-2 me-2 w-100"
                                    >
                                        Eksportuj
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                </Container>
            </React.Fragment>
        );
    }
}

export default AudioplayerLogView;
