import http from "./httpService";

const apiEndpoint ="/api/musicfiles";


export function add(data) {
    return http.post(apiEndpoint, data);
}

export function getAll() {
    return http.get(apiEndpoint+"/");
}

export function remove(id) {
    return http.delete(apiEndpoint+"/"+id);
}

const MusicFilesService = {add,getAll,remove};

export default MusicFilesService;

