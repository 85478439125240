import http from "./httpService";

import config from "../config.json";

const apiEndpoint = "/api/deviceDefaultConfigurations";

// export function updateValue(data) {
//     return http.put(apiEndpoint + "/value/" + data.what, data);
// }

export function add(data) {
    return http.post(apiEndpoint, data);
}

export function modify(data,id) {
    return http.put(apiEndpoint + "/"+id, data);
}


export function getByType(dev_type) {
    return http.get(apiEndpoint + "/" + dev_type);
}

// export function getAll() {
//     return http.get(apiEndpoint);
// }

export function remove(id){
    return http.delete(apiEndpoint+"/"+id);
}

const DeviceDefaultConfigurations = { add, modify, getByType, remove };

export default DeviceDefaultConfigurations;
