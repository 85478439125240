import React, {Component, useContext} from "react";
import {Link} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import {FaCircleInfo} from "react-icons/fa6";


import {toast} from "react-toastify";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faSave,
    faTimes,
    faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";

import dictionary from "../PL.json";
import config from "../config.json";

import AuthDevicesContext from "../contexts/authDevicesContext";
import * as AuthDeviceService from "../services/authDeviceService";
import ShopService from "../services/shopService";
import TagService from "../services/tagService";
import ApiSettingsService from "../services/apiSettingsService";
import {getCurrentUser} from "../services/authService";

import AuthDevicesModifyModal from "./authDevicesModifyModal";

import Pagination from "./common/pagination";
import {prepareTableForList} from "../utils/devType";
import {paginate} from "../utils/paginate";
import {search} from "../utils/search";
import SimpleSelect from "./common/simpleSelect";

import UListGroup from "./common/uListGroup";
import Sync from "./common/sync";
import AuthDevicesTable from "./authDevicesTable";

import _ from "lodash";
import SearchBox from "./common/searchBox";

import {isAdminOrIsService, isAdmin} from "../services/authService";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import SearchBoxInfo from "./common/tooltip/searchBoxInfo";

// import searchBoxInfo from "./common/tooltip/searchBoxInfo";

class AuthDevices extends Component {
    state = {
        authDevices: [],
        devTypes: [],
        shops: [],
        tags: [],
        pageSize: 100,
        currentPage: 1,
        selectedDevType: null,
        sortColumn: {path: "title", order: "asc"},
        searchQuery: "",
        selectedDevice: null,
        checkedVisible: true,
        settingsMode: false,
        checkingWindow: false,
        filterAdvanced: true,
        selectedCount: 0,
        modifyModal: false,
        selectedTag: "",
        selectedTags: [],
        tagEdit: false,
        migrationEnabled: false,
        migrationMode: false,
        migrationEdit: false,
        apiSettings: [],
        selectedFwForFiltering: "",
        fwFilesForFilter: [],
        selectedHwForFiltering: "",
    };

    async componentDidMount() {
        try {
            const {data: devices} = await AuthDeviceService.getAll();

            const {data: shops} = await ShopService.getAll();

            const {data: tags} = await TagService.getAll();

            const {data: apiSettings} = await ApiSettingsService.getAll();

            // what===2 oznacza że jest nowe API dostępne do przełaczenia
            const result = apiSettings.find((api) => api.what === "new_endpoint");
            let migrationEnabled = false;
            if (result) if (result.enable === true) migrationEnabled = true;

            let fwFilesForFilter = [];
            let counter = 0;
            devices.forEach((device) => {
                const exist = fwFilesForFilter.find((fw) => fw.name === device.fw_version);
                if (!exist) {
                    fwFilesForFilter.push({_id: counter, name: device.fw_version});
                    counter++;
                }
            });

            const devTypes = prepareTableForList();
            this.setState({
                authDevices: this.addShopInfoAndCheckMark(devices, shops),
                fwFilesForFilter: this.updateFwFilesForFiltering(devices),
                devTypes,
                selectedDevType: devTypes?.[0],
                shops,
                tags,
                apiSettings,
                selectedDevice: null,
                migrationEnabled
            });
        } catch (err) {
            toast.error("Błąd serwera!!!");
            this.props.history.push("/not-found");
        }
    }

    ifAnyTypeOfDeviceSelected = () => {
        const {selectedDevType} = this.state;
        if (selectedDevType && selectedDevType.type !== "") {
            return true;
        }
        return false;
    };

    addShopInfoAndCheckMark = (devices, shops) => {
        let authDevices = [];
        if (devices.length !== 0) {
            authDevices = devices.map((device) => {
                device.checked = false;
                if (shops.length !== 0) {
                    const shop = shops.filter((shop) => shop._id === device.shop_id);

                    if (shop.length !== 0)
                        return {
                            shop_city: shop[0].city,
                            shop_name: shop[0].name,
                            ...device,
                        };
                    return device;
                }
                return device;
            });
        }
        return authDevices;
    };

    handleCheckAll = (sameTypeOnly) => {
        let {authDevices, selectedDevType} = this.state;

        const filteredDevices = this.getfilteredData();

        let selectedCount = 0;

        authDevices.forEach((device) => {
            const isInOnFilteredList = filteredDevices.find(
                (x) => x._id === device._id
            );
            if (isInOnFilteredList) {
                if (sameTypeOnly) {
                    if (this.ifAnyTypeOfDeviceSelected()) {
                        if (device.dev_type === selectedDevType.type) {
                            device.checked = true;
                            selectedCount++;
                        }
                    }
                } else {
                    device.checked = true;
                    selectedCount++;
                }
            }
        });

        this.setState({authDevices, selectedCount});
    };

    handleUncheckAll = () => {
        let {authDevices, selectedDevType} = this.state;

        // if (this.ifAnyTypeOfDeviceSelected()) {
        let selectedCount = 0;
        authDevices.forEach((device) => {
            device.checked = false;
        });

        authDevices.forEach((device) => {
            if (device.checked) selectedCount++;
        });

        this.setState({authDevices, selectedCount});
        // }
    };

    handleCheck = async (event, deviceClicked) => {
        if (event) event.stopPropagation();

        let {authDevices, settingsMode, migrationMode} = this.state;

        if (this.ifAnyTypeOfDeviceSelected() || ((settingsMode === false) || migrationMode)) {
            let index = authDevices.findIndex(
                (device) => device._id === deviceClicked._id
            );

            if (index !== -1) {
                authDevices[index].checked = event.target?.checked;
            }

            let selectedCount = 0;

            authDevices.forEach((device) => {
                if (device.checked) selectedCount++;
            });

            this.setState({authDevices, selectedCount});
        }
    };

    handleDelete = async (event, authDevice) => {
        if (event) event.stopPropagation();

        const {data} = await AuthDeviceService.remove(authDevice._id);

        const {data: authDevices} = await AuthDeviceService.getAll();

        this.setState({
            authDevices: this.addShopInfoAndCheckMark(authDevices, this.state.shops),
        });
    };

    handleEdit = async (event, authDevice) => {
        if (event) event.stopPropagation();

        return this.props.history.replace("/shopView/" + authDevice.shop_id);
    };

    handleRowClick = (AuthDevice) => {
    };

    handlePageChange = (page) => {
        this.setState({currentPage: page});
    };

    handleDevTypeSelect = (devType) => {
        const {authDevices} = this.state;

        //Czyszczenie zaznaczenia
        authDevices.forEach((device) => {
            device.checked = false;
        });

        if (isAnyDevTypeSelected(devType) === false) {
            this.setState({settingsMode: false});
        }

        this.setState({
            authDevices,
            selectedDevType: devType,
            // searchQuery: "",
            currentPage: 1,
            selectedCount: 0,
        });
    };

    handleSort = (sortColumn) => {
        if (sortColumn.path === "") return;

        this.setState({sortColumn});
    };

    getfilteredData = () => {
        const {
            authDevices: allAuthDevices,
            selectedDevType,
            searchQuery,
            selectedTags,
        } = this.state;

        let filtered = allAuthDevices;

        if (searchQuery) {
            filtered = search(
                allAuthDevices,
                ["sn", "fw_version", "hw_version", "shop_city"],
                searchQuery
            );
        }

        if (selectedDevType && selectedDevType._id) {
            filtered = search(filtered, ["dev_type"], selectedDevType.type, true);
        }

        if (selectedTags.length > 0) {
            filtered = filtered.filter((device) => {
                const exist = device.tags.filter((tag) =>
                    selectedTags.some((x) => x === tag)
                );
                if (exist.length > 0) return true;
                else return false;
            });
        }

        return filtered;
    };
    getPagedData = () => {
        const {pageSize, currentPage, sortColumn} = this.state;

        const filtered = this.getfilteredData();

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const authDevices = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: authDevices};
    };

    handleSearch = (query) => {
        this.setState({
            searchQuery: query,
            // selectedDevType: null,
            currentPage: 1,
        });
    };

    handleModalHide = async () => {
        this.setState({modifyModal: false, tagEdit: false, migrationEdit: false});
    };

    prepareOptions = () => {
        const {tags, selectedDevType, selectedTags} = this.state;
        let result = _.cloneDeep(tags);
        if (this.ifAnyTypeOfDeviceSelected()) {
            result = result.filter((tagFromAll) => {
                const exist = tagFromAll.dev_types.find(
                    (type) => type === selectedDevType.type
                );
                if (exist) return true;
                return false;
            });
        }

        if (selectedTags.length > 0) {
            result = result.filter((tagFilteredBySelectedDevice) => {
                const exist = selectedTags.every(
                    (x) => x !== tagFilteredBySelectedDevice._id
                );
                if (exist) return true;
                else return false;
            });
        }

        return result;
    };

    addTag = () => {
        const {tags, selectedTag, selectedTags} = this.state;

        if (selectedTag === "") return false;

        const result = selectedTags.find((tag) => tag === selectedTag);
        if (result) return false;

        const _selectedTags = _.cloneDeep(selectedTags);
        _selectedTags.push(selectedTag);

        this.setState({selectedTags: _selectedTags});
    };

    removeTag = (element) => {
        const {tags, selectedTag, selectedTags} = this.state;
        const index = selectedTags.findIndex((tag) => tag === element);
        if (index === -1) return;
        selectedTags.splice(index, 1);
        this.setState({selectedTags});
    };



    // filterFwFilesForFiltering = () => {
    //     let {fwFilesForFilter, selectedDevType} = this.state;
    //
    //     if (!fwFilesForFilter) return [];
    //     const filtered = fwFilesForFilter.filter((fw) => fw.type === selectedDevType.type);
    //     if (!filtered) return [];
    //     return filtered;
    // };

    filterFwFilesForFiltering = () => {
        let {fwFilesForFilter, selectedDevType} = this.state;

        if (!fwFilesForFilter) return [];
        const filtered = fwFilesForFilter.filter((fw) => fw.type === selectedDevType.type);
        if (!filtered) return [];
        return filtered;
    };

    filterHWFilesForFiltering = () => {
        let {authDevices, selectedDevType} = this.state;

        const hwFiltered = [];
        if (authDevices) {

            authDevices.forEach((device, id) => {
                if (device.dev_type !== selectedDevType.type) return;
                if (device.hw_version === null) return;
                hwFiltered.findIndex((hw) => hw._id === device.hw_version) === -1 && hwFiltered.push({
                    _id: device.hw_version,
                    name: device.hw_version
                });
                return;
            });
        }

        return hwFiltered;
    };

    updateFwFilesForFiltering = (devices) => {
        let fwFilesForFilter = [];
        let counter = 0;
        devices.forEach((device) => {
            const exist = fwFilesForFilter.find((fw) => {
                if (fw.name === device.fw_version) {
                    if (fw.type === device.dev_type) {
                        return true;
                    }
                }
                return false;
            });
            if (!exist) {
                fwFilesForFilter.push({_id: counter, name: device.fw_version, type: device.dev_type});
                counter++;
            }
        });

        return fwFilesForFilter;
    };

    // handleSelectedFwChange = (selectedFw) => {
    //     this.setState({selectedFw});
    // };

    handleSelectedFwForFilteringChange = (selectedFwForFiltering) => {
        this.setState({selectedFwForFiltering: Number(selectedFwForFiltering)});
    };

    handleSelectedHwForFilteringChange = (selectedHwForFiltering) => {
        this.setState({selectedHwForFiltering: selectedHwForFiltering});
    };

    handleCheckWithFw = () => {
        const {selectedFwForFiltering: fw, fwFilesForFilter, authDevices} = this.state;

        const file = fwFilesForFilter.find((file) => (file._id === fw));

        if (file) {
            authDevices.forEach((device) => {

                if (device.dev_type === file.type) {
                    if (device.fw_version === file.name) {
                        device.checked = true;
                    }
                }
            });
            this.setState({authDevices});
        }
    };

    handleUncheckWithFw = () => {
        const {selectedFwForFiltering: fw, fwFilesForFilter, authDevices} = this.state;

        let selectedCount = 0;

        const file = fwFilesForFilter.find((file) => (file._id === fw));

        if (file) {
            authDevices.forEach((device) => {

                if (device.dev_type === file.type) {
                    if (device.fw_version === file.name) {
                        device.checked = false;
                    }
                }

                if(device.checked === true) selectedCount++;
            });
            this.setState({authDevices,selectedCount});
        }
    };

    handleCheckWithHw = () => {
        const {selectedHwForFiltering: hw, authDevices,selectedDevType} = this.state;

        let selectedCount = 0;

        authDevices.forEach((device) => {

            if (device.hw_version === hw && device.dev_type === selectedDevType.type) {

                device.checked = true;

            }

            if(device.checked === true) selectedCount++;
        });
        this.setState({authDevices,selectedCount});

    };

    handleUncheckWithHw = () => {
        const {selectedHwForFiltering: hw, authDevices} = this.state;

        let selectedCount = 0;

        authDevices.forEach((device) => {

            if (device.hw_version === hw) {

                device.checked = false;

            }
            if(device.checked === true) selectedCount++;

        });
        this.setState({authDevices,selectedCount});

    };

    countSelectedDevices = () => {
        const {authDevices} = this.state;
        let counter = 0;

        authDevices.forEach((device) => {
            if (device.checked) counter++;
        });

        return counter;
    };

    render() {
        const count = this.state.authDevices.length;
        const {
            pageSize,
            currentPage,
            devTypes,
            selectedDevType,
            sortColumn,
            searchQuery,
            shops,
            settingsMode,
            checkingWindow,
            filterAdvanced,
            selectedCount,
            modifyModal,
            tags,
            selectedTag,
            selectedTags,
            tagEdit,
            migrationMode,
            migrationEnabled,
            migrationEdit
        } = this.state;

        const {totalCount, data: authDevices} = this.getPagedData();

        const howManyDeviceIsSelected = this.countSelectedDevices();

        return (
            <AuthDevicesContext.Provider
                value={{
                    devices: this.state.authDevices,
                    selectedCount: howManyDeviceIsSelected,
                    selectedDevType: selectedDevType,
                    tags: tags,
                    tagEdit: tagEdit,
                    migrationEdit: migrationEdit
                }}
            >
                <ConfirmationModalContextProvider>
                    <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                        <Breadcrumb.Item
                            style={{
                                marginTop: 3,
                                marginLeft: 3,
                                marginBottom: 3,
                                marginRight: 0,
                            }}
                            href="/"
                        >
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            style={{
                                marginTop: 3,
                                marginLeft: 3,
                                marginBottom: 3,
                                marginRight: 0,
                            }}
                            active
                        >
                            Lista urządzeń
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Container fluid>
                        <Row>
                            {count !== 0 && (
                                <Col xs="11">
                                    <div className="d-flex justify-content-center">
                                        <h3>Ilość wybranych urządzeń: {totalCount}</h3>
                                    </div>
                                </Col>
                            )}
                            {count === 0 && (
                                <Col xs="11">
                                    <div className="d-flex justify-content-center">
                                        <h3>Brak urządzeń w systemie</h3>
                                    </div>
                                </Col>
                            )}

                            <Col xs="1">
                                <div className="d-flex justify-content-center">
                                    <Sync onClick={() => (window.location = "/authDevices")}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className={"pt-1"}>
                                <div className={"d-flex flex-row align-items-center justify-content-center"}>
                                    <div className={"w-50"}><SearchBox value={searchQuery}
                                                                       onChange={this.handleSearch}/></div>
                                    <div>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{show: 10, hide: 0}}
                                            overlay={(props) => SearchBoxInfo(props)}
                                        >
                                            <div><FaCircleInfo size={"1.5rem"} style={{textAlign: "center"}}
                                                               className={"mx-1"}/></div>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col lg="2">
                                <UListGroup
                                    items={devTypes}
                                    onItemSelect={this.handleDevTypeSelect}
                                    selectedItem={selectedDevType}
                                    horizontal={false}
                                />
                                {isAdmin() && (
                                    <React.Fragment>
                                        <Accordion
                                            defaultActiveKey=""
                                            activeKey={filterAdvanced ? "0" : "1"}
                                            className="mt-2"
                                        >
                                            <Accordion.Item
                                                eventKey="0"
                                                className="border-primary border-"
                                            >
                                                <Accordion.Header
                                                    onClick={(event) => {
                                                        this.setState({filterAdvanced: !filterAdvanced});
                                                    }}
                                                >
                                                    <div className="w-100 text-center">
                                                        <span className="fw-bold">
                                                          Filtrowanie po tagach
                                                        </span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="tag-container">
                                                        {selectedTags.map((tag) => {
                                                            const result = tags.find(
                                                                (tagFromAll) => tagFromAll._id === tag
                                                            );
                                                            if (!result) return null;
                                                            return (
                                                                <div className="tag" key={result._id}>
                                                                    <div className="tag__name">{result.name}</div>

                                                                    <FontAwesomeIcon
                                                                        onClick={() => this.removeTag(result._id)}
                                                                        className="tag__delete"
                                                                        icon={faTimes}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <SimpleSelect
                                                        className="my-2 w-100"
                                                        value={selectedTag}
                                                        optionName="name"
                                                        optionKey="_id"
                                                        options={this.prepareOptions()}
                                                        onChange={(e) =>
                                                            this.setState({selectedTag: e.target.value})
                                                        }
                                                    />

                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        className="fw-bold w-100"
                                                        onClick={this.addTag}
                                                    >
                                                        Dodaj tag
                                                    </Button>
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        className="mt-1 fw-bold w-100"
                                                        onClick={() => this.setState({selectedTags: []})}
                                                    >
                                                        Wyczyść tagi
                                                    </Button>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </React.Fragment>
                                )}

                                {isAdmin() && this.ifAnyTypeOfDeviceSelected() && (
                                    <React.Fragment>
                                        <Accordion
                                            defaultActiveKey=""
                                            activeKey={settingsMode ? "0" : "1"}
                                            className="mt-2"
                                        >
                                            <Accordion.Item
                                                eventKey="0"
                                                className="border-primary border-"
                                            >
                                                <Accordion.Header
                                                    onClick={(event) => {
                                                        this.setState({settingsMode: !settingsMode});
                                                    }}
                                                >
                                                    <div className="w-100 text-center">
                                                        <span className="fw-bold">Ustawienia masowe</span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        className="fw-bold w-100"
                                                        onClick={() => this.handleCheckAll(true)}
                                                    >
                                                        Zaznacz wszystkie
                                                    </Button>
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        className="fw-bold w-100 mt-1"
                                                        onClick={() => this.handleUncheckAll()}
                                                    >
                                                        Odznacz wszystkie
                                                    </Button>
                                                    <hr></hr>
                                                    <div
                                                        className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                                        <label className="align-self-center fw-bold">FW:</label>
                                                        <SimpleSelect
                                                            value={this.state.selectedFwForFiltering}
                                                            className="m-2 w-100"
                                                            optionName="name"
                                                            optionKey="_id"
                                                            options={this.filterFwFilesForFiltering()}
                                                            onChange={(e) =>
                                                                this.handleSelectedFwForFilteringChange(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        className="fw-bold w-100 mt-1"
                                                        onClick={() => this.handleCheckWithFw()}
                                                    >
                                                        Zaznacz po FW
                                                    </Button>
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        className="fw-bold w-100 mt-1"
                                                        onClick={() => this.handleUncheckWithFw()}
                                                    >
                                                        Odznacz po FW
                                                    </Button>
                                                    <hr></hr>
                                                    <div
                                                        className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                                        <label className="align-self-center fw-bold">HW:</label>
                                                        <SimpleSelect
                                                            value={this.state.selectedHwForFiltering}
                                                            className="m-2 w-100"
                                                            optionName="name"
                                                            optionKey="_id"
                                                            options={this.filterHWFilesForFiltering(authDevices, selectedDevType)}
                                                            onChange={(e) =>
                                                                this.handleSelectedHwForFilteringChange(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        className="fw-bold w-100 mt-1"
                                                        onClick={() => this.handleCheckWithHw()}
                                                    >
                                                        Zaznacz po HW
                                                    </Button>
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        className="fw-bold w-100 mt-1"
                                                        onClick={() => this.handleUncheckWithHw()}
                                                    >
                                                        Odznacz po HW
                                                    </Button>
                                                    <hr></hr>
                                                    <p className="text-center mb-1">
                                                        Zaznaczonych:{" "}
                                                        <span className="fw-bold">{howManyDeviceIsSelected}</span>
                                                    </p>
                                                    {isAnyDevTypeSelected(selectedDevType) && (
                                                        <Button
                                                            variant="success"
                                                            disabled={howManyDeviceIsSelected ? false : true}
                                                            className="fw-bold w-100 mb-1"
                                                            onClick={() =>
                                                                this.setState({
                                                                    modifyModal: true,
                                                                    tagEdit: false,
                                                                })
                                                            }
                                                        >
                                                            Modyfikuj Ustawienia
                                                        </Button>
                                                    )}

                                                    {isAnyDevTypeSelected(selectedDevType) && (
                                                        <Button
                                                            variant="success"
                                                            disabled={howManyDeviceIsSelected ? false : true}
                                                            className="fw-bold w-100"
                                                            onClick={() =>
                                                                this.setState({
                                                                    modifyModal: true,
                                                                    tagEdit: true,
                                                                })
                                                            }
                                                        >
                                                            Edytuj tagi
                                                        </Button>
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </React.Fragment>
                                )}
                                {isAdmin() && migrationEnabled && (
                                    <React.Fragment>
                                        <Accordion
                                            defaultActiveKey=""
                                            activeKey={migrationMode ? "0" : "1"}
                                            className="my-2"
                                        >
                                            <Accordion.Item
                                                eventKey="0"
                                                className="border-primary border-"
                                            >
                                                <Accordion.Header
                                                    onClick={(event) => {
                                                        this.setState({migrationMode: !migrationMode});
                                                    }}
                                                >
                                                    <div className="w-100 text-center">
                                                        <span className="fw-bold">Migracja</span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        className="fw-bold w-100"
                                                        onClick={() => this.handleCheckAll(false)}
                                                    >
                                                        Zaznacz wszystkie
                                                    </Button>
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        className="fw-bold w-100 mt-1"
                                                        onClick={() => this.handleUncheckAll()}
                                                    >
                                                        Odznacz wszystkie
                                                    </Button>
                                                    <p className="text-center mb-1">
                                                        Zaznaczonych:{" "}
                                                        <span className="fw-bold">{selectedCount}</span>
                                                    </p>
                                                    {
                                                        <Button
                                                            variant="success"
                                                            disabled={selectedCount ? false : true}
                                                            className="fw-bold w-100 mb-1"
                                                            onClick={() =>
                                                                this.setState({
                                                                    modifyModal: true,
                                                                    migrationEdit: true,
                                                                })
                                                            }
                                                        >
                                                            Przełącz nowe API
                                                        </Button>
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </React.Fragment>
                                )}
                            </Col>
                            <Col lg="10">
                                <Row className={"mb-1"}>
                                    <Col xs="12" lg="12">
                                        <Pagination
                                            itemsCount={totalCount}
                                            pageSize={pageSize}
                                            currentPage={currentPage}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AuthDevicesTable
                                            authDevices={authDevices}
                                            shops={shops}
                                            sortColumn={sortColumn}
                                            onDelete={this.handleDelete}
                                            onEdit={this.handleEdit}
                                            onAdd={this.handleAdd}
                                            onSort={this.handleSort}
                                            onCheckClick={this.handleCheck}
                                            onRowClick={this.handleRowClick}
                                            checkedVisible={settingsMode || migrationMode}
                                            tags={tags}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-1"}>
                                    <Col xs="12" lg="12">
                                        <Pagination
                                            itemsCount={totalCount}
                                            pageSize={pageSize}
                                            currentPage={currentPage}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {isAdmin() && (
                            <AuthDevicesModifyModal
                                show={modifyModal}
                                onHide={this.handleModalHide}
                                onEnter={this.handleModalEnter}
                            />
                        )}
                    </Container>
                </ConfirmationModalContextProvider>
            </AuthDevicesContext.Provider>
        );
    }
}

function isAnyDevTypeSelected(devtype) {
    if (devtype?.type) {
        return true;
    } else {
        return false;
    }
}

export default AuthDevices;
