import http from "./httpService";

const apiEndpoint ="/api/paths";

export function add(data){
    return http.post(apiEndpoint,data);
}

export function getRoot(){
    return http.get(apiEndpoint+"/root");
}

export function get(parentId){
    return http.get(apiEndpoint+"/parent/"+parentId);
}

export function getAll(){
    return http.get(apiEndpoint+"/");
}


//
// function update(_id,data){
//     return http.put(apiEndpoint+"/"+_id,data);
// }
//
// function get(_id){
//     return http.get(apiEndpoint+"/"+_id);
// }
//
// function getAll(){
//     return http.get(apiEndpoint);
// }

// function getAllWithDevicesAssigned(){
//     return http.get(apiEndpoint+"/inuse");
// }

export function remove(_id){
    return http.delete(apiEndpoint+"/"+_id);
}

const PathService = {add,getRoot,get,getAll,remove};

export default PathService;

