import http from "./httpService";

const apiEndpoint = "/api/authDevicesUpdate";





export function postUpdate(data) {
  return http.post(apiEndpoint + "/update",data);
}

export function postCancel(data) {
  return http.post(apiEndpoint + "/cancel",data);
}


