import http from "./httpService";

const apiEndpoint ="/api/wifi";

export function add(data){
    return http.post(apiEndpoint,data);
}


// export function update(_id,data){
//     return http.put(apiEndpoint+"/"+_id,data);
// }


export function getAll(){
    return http.get(apiEndpoint);
}

export function get(_id){
    return http.get(apiEndpoint+"/"+_id);
}



export function getAllForShop(shopId){
    return http.get(apiEndpoint+"/shop/" + shopId);
}

export function removeShop(_id,shopId){
    return http.delete(apiEndpoint+"/"+_id + "/" +shopId);
}

const WifiService = {add,getAll,get,removeShop,getAllForShop};

export default WifiService;


