import http from "./httpService";

const apiEndpoint = "/api/unauthdevices";

// function add(data){
//     return http.post(apiEndpoint,data);
// }

// function update(_id,data){
//     return http.put(apiEndpoint+"/"+_id,data);
// }

// function get(_id){
//     return http.get(apiEndpoint+"/"+_id);
// }

function getAll() {
  return http.get(apiEndpoint);
}

function remove(_id) {
  return http.delete(apiEndpoint + "/" + _id);
}

export default {
//   add,
//   update,
//   get,
  getAll,
  remove,
};
