import http from "./httpService";

const apiEndpoint = "/api/audioplayerLog";

export function getByShopAndDay(shopId,day){
    return http.get(apiEndpoint+"/"+shopId + "/date/" + day);
}





